import React, { useState } from 'react';
import Slider from 'react-slick';
import './App.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import instimage from "./images/inst.png";
import icon from "./images/hous.png";
import side from "./images/Side.jpeg";
import side1 from "./images/side1.jpeg";
import side3 from "./images/side3.jpeg";
import electrical from "./images/Electrical.jpeg";

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', blurb: '' });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const subject = encodeURIComponent('RFQ - Services');
    const body = encodeURIComponent(`Name: ${formData.name}\nEmail: ${formData.email}\n\nProject Details:\n${formData.blurb}`);
    window.location.href = `mailto:etvdevelopment@gmail.com?subject=${subject}&body=${body}`;
    setShowPopup(false);
    setFormData({ name: '', email: '', blurb: '' });
  };

  return (
    <div className="App">
      <header className="App-header">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" />
        <link rel="icon" href="construction-landing-page\frontend\src\images\hous.ico"/>
        
        <div className="header-content">
          <img src={icon} alt="Construction site" className="header-image" />
          <div className="header-text">
            <h1>ETV DEVELOPMENT INC</h1>
            <p>Where Quality Meets Craftsmanship!</p>
            <p>General Contractors</p>
          </div>
        </div>
        <button className="quote-button" onClick={() => setShowPopup(true)}>Email Us for a Quote</button>
      </header>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Request a Quote</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Your Name"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Your Email"
                required
              />
              <textarea
                name="blurb"
                value={formData.blurb}
                onChange={handleInputChange}
                placeholder="Tell us about your project or needs"
                required
              />
              <div className="popup-buttons">
                <button type="submit">Send</button>
                <button type="button" onClick={() => setShowPopup(false)}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      )}


      
      <main>
        <section>
          <h2>Our Services</h2>
          <ul>
            <li>Residential Construction</li>
            <li>Commercial Construction</li>
            <li>Renovations</li>
            <li>Project Management</li>
          </ul>
        </section>
       
        <section className="carousel-section">
          <h2>Our Projects</h2>
          <div className="carousel-container">
            <Slider {...settings}>
              <div><img src={side} alt="Project 1" /></div>
              <div><img src={side1} alt="Project 2" /></div>
              <div><img src={side3} alt="Project 3" /></div>
              <div><img src={electrical} alt="Project 4" /></div>
            </Slider>
          </div>
        </section>

        <section>
          <h2>Why Choose Us?</h2>
          <ul>
            <li>Over 20 years of experience</li>
            <li>Additions/ADU and New Construction</li>
            <li>Home Remodeling/Improvement</li>
            <li>Foundations</li>
            <li>Framing</li>
            <li>Electrical</li>
            <li>Drywall</li>
            <li>Painting</li>
          </ul>
        </section>
        <section>
          <h2>Contact Us</h2>
          <p>Email: etvdevelopment@gmail.com</p>
          <p>Phone: (626) 501-2517</p>
          <p>ETV Development Inc</p>
          <p>License #1092152</p>
          <p className='centered-content'>
            <img src={instimage} alt="Construction site" className="small-image" />
            <a href="https://www.instagram.com/etvdevelopmentinc" target="_blank" rel="noopener noreferrer">@etvdevelopmentinc</a>
          </p>

        
        </section>
        
      </main>
      <footer>
        <p>&copy; 2024 ETV Development Inc. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;